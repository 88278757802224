import createSvgIcon from "@mui/material/utils/createSvgIcon";
const Review = createSvgIcon(<svg viewBox="0 0 16 16" fill="currentColor">
    <g clipPath="url(#clip0_7861_60245)">
      <path d="M11.25 4.5H4.75C4.3375 4.5 4 4.8375 4 5.25C4 5.6625 4.3375 6 4.75 6H11.25C11.6656 6 12 5.66563 12 5.25C12 4.83437 11.6656 4.5 11.25 4.5ZM8.25 7.5H4.75C4.3375 7.5 4 7.8375 4 8.25C4 8.6625 4.3375 9 4.75 9H8.25C8.66563 9 9 8.66563 9 8.25C9 7.83437 8.66563 7.5 8.25 7.5Z" fill="currentColor" className="secondary" />
      <path d="M13.9719 0H1.97187C0.870312 0 -0.028125 0.898438 -0.028125 1.97188V10.9438C-0.028125 12.0453 0.870312 12.9156 1.97187 12.9156H4.97187V15.5125C4.97187 15.8172 5.32344 15.9953 5.56937 15.8156L9.4725 12.8875H13.9725C15.0741 12.8875 15.9725 11.9891 15.9725 10.9156V1.97188C15.9719 0.898438 15.1 0 13.9719 0ZM8.25 9H4.75C4.3375 9 4 8.66563 4 8.25C4 7.83438 4.3375 7.5 4.75 7.5H8.25C8.66562 7.5 9 7.8375 9 8.25C9 8.6625 8.66562 9 8.25 9ZM11.25 6H4.75C4.3375 6 4 5.66563 4 5.25C4 4.83438 4.3375 4.5 4.75 4.5H11.25C11.6656 4.5 12 4.8375 12 5.25C12 5.6625 11.6656 6 11.25 6Z" fill="currentColor" />
    </g>

    <defs>
      <clipPath id="clip0_7861_60245">
        <rect width="16" height="16" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>, "Review");
export default Review;